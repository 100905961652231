import React from "react";
import Box from "@mui/material/Box";

const Topbar = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
      style={{ height: "50px" }}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="FMR TECH"
        width={{ xs: 100, md: 130 }}
      >
        <Box
          component={"img"}
          src={
            "https://res.cloudinary.com/fmrtech/image/upload/v1653438328/FmrTech/Illustrations/fmrTech3_mzrbtj.svg"
          }
          height={1}
          width={1}
        />
      </Box>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        alignItems={"center"}
      ></Box>
    </Box>
  );
};

export default Topbar;
