import React from "react";
import "./App.css";
import Page from "./components/Page/Page";
import Routes from "./Routes";
import { BrowserRouter } from "react-router-dom";

import "react-lazy-load-image-component/src/effects/blur.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import "aos/dist/aos.css";
import "@material-tailwind/react/tailwind.css";

function App() {
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
}

export default App;
