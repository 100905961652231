import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const mock = [
  {
    title: 100,
    subtitle: "Highly skilled UI/UX Designers.",
    suffix: "%",
  },
  {
    title: 100,
    subtitle: "Highly skilled developers.",
    suffix: "%",
  },
  {
    title: 99,
    subtitle:
      "99% of our customers rated 5-star on our projects over the years.",
    suffix: "%",
  },
];

const Features = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? "row" : "column-reverse"}>
        <Grid item xs={12} md={6} data-aos={isMd ? "fade-right" : "fade-up"}>
          <Box marginBottom={4}>
            <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
              Stay focused on your business. Let us handle the design.
            </Typography>
            <Typography component={"p"} color={"text.secondary"}>
              You have a business to run. Stop stressing about cross-browser
              bugs, designing new pages, keeping your components up to date. Let
              us take care of that.
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              {mock.map((item, i) => (
                <Grid key={i} item xs={12} md={4}>
                  <Typography variant="h4" color={"primary"} gutterBottom>
                    <Box fontWeight={600}>
                      <VisibilitySensor
                        onChange={(isVisible) =>
                          setViewPortVisibility(isVisible)
                        }
                        delayedCall
                      >
                        <CountUp
                          redraw={false}
                          end={viewPortEntered ? item.title : 0}
                          start={0}
                          suffix={item.suffix}
                        />
                      </VisibilitySensor>
                    </Box>
                  </Typography>
                  <Typography component="p">{item.subtitle}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item container justifyContent={"center"} xs={12} md={6}>
          <Box height={1} width={1} maxHeight={800}>
            <Box
              component={LazyLoadImage}
              effect="blur"
              src={
                "https://res.cloudinary.com/fmrtech/image/upload/v1653358639/FmrTech/Illustrations/SEO_z6x1gt.svg"
              }
              height={{ xs: "auto", md: 1 }}
              maxHeight={{ xs: 300, md: 1 }}
              width={1}
              maxWidth={1}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
