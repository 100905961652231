import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box
            display={"flex"}
            component="a"
            href="/"
            title="FMR TECH"
            // width={50}
            // height={50}
            width={{ xs: 60, md: 90 }}
          >
            <Box
              component={"img"}
              src={
                "https://res.cloudinary.com/fmrtech/image/upload/v1653438328/FmrTech/Illustrations/fmrTech3_mzrbtj.svg"
              }
              height={1}
              width={1}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
          marginTop={2}
        >
          &copy; Fmr Tech. {new Date().getFullYear()}. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
