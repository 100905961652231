import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Main from "../../Layouts/Main/Main";
import Container from "../Container/Container";
import { About, Contact, Features, Header } from "./smallerComponents";

const Dashboard = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        position={"relative"}
        sx={{
          backgroundColor: theme.palette.alternate.main,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container>
          <Header />
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: "100%",
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          />
        </Box>
      </Box>
      <Container paddingTop={"0 !important"}>
        <Features />
      </Container>
      <Box
        position={"relative"}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box>
          <Container>
            <Contact />
          </Container>
          <Container paddingTop={"0 !important"}>
            <About />
          </Container>
        </Box>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: "100%",
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          />
        </Box>
      </Box>
      {/*<Container paddingTop={'1 !important'}>*/}
      {/*  <Users />*/}
      {/*</Container>*/}
    </Main>
  );
};

export default Dashboard;
