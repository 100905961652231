/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Typed from "react-typed";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import * as emailjs from "@emailjs/browser";

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid full name")
    .max(50, "Please enter a valid full name")
    .required("Please specify your full name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required"),
  subject: yup.string().trim().required("Type your subject"),
  message: yup
    .string()
    .trim()
    .min(5, "Enter in detail the service needed")
    .max(500, "Enter in detail the service needed")
    .required("Type your message"),
});

const Contact = () => {
  const theme = useTheme();

  const initialValues = {
    fullName: "",
    email: "",
    subject: "",
    message: "",
    to_name: "Fmr Tech",
  };

  const onSubmit = () => {
    emailjs
      .send(
        "service_68dihjm",
        "template_eczx1so",
        formik.values,
        "hxbww3OWbiD7PFOAi"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} data-aos={"fade-up"}>
          <Box maxWidth={400} margin={"0 auto"}>
            <Card
              sx={{
                backgroundImage: "none",
                boxShadow: 4,
              }}
            >
              <CardMedia
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  component={LazyLoadImage}
                  effect="blur"
                  src={
                    "https://res.cloudinary.com/fmrtech/image/upload/v1653358823/FmrTech/Illustrations/Forms_tfhvmz.svg"
                  }
                  height={{ xs: 240, sm: 340, md: 280 }}
                  width={1}
                  sx={{
                    objectFit: "cover",
                  }}
                />
                <Box
                  component={"svg"}
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                  sx={{
                    width: "100%",
                    bottom: 0,
                    position: "absolute",
                  }}
                >
                  <path
                    fill={theme.palette.background.default}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  />
                </Box>
              </CardMedia>
              <CardContent>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Name"
                        name="fullName"
                        fullWidth
                        type="text"
                        value={formik.values.fullName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.fullName &&
                          Boolean(formik.errors.fullName)
                        }
                        helperText={
                          formik.touched.fullName && formik.errors.fullName
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        name="email"
                        fullWidth
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Subject"
                        name="subject"
                        fullWidth
                        type="text"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.subject &&
                          Boolean(formik.errors.subject)
                        }
                        helperText={
                          formik.touched.subject && formik.errors.subject
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        name="message"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid
          item
          container
          alignItems={"center"}
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Box>
            <Box marginBottom={2}>
              <Typography variant={"h4"} sx={{ fontWeight: 700 }} gutterBottom>
                Please fill out the form to{" "}
                <Typography
                  color={"primary"}
                  component={"span"}
                  variant={"inherit"}
                >
                  <Typed
                    strings={["Reach Us", "Contact Us", "Email Us"]}
                    typeSpeed={80}
                    loop={true}
                  />
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
