import React from "react";
import {
  FmrTech as FmrTechView,
  NotFound as NotFoundView,
} from "../components";

const routes = [
  {
    path: "/",
    renderer: (params = {}) => <FmrTechView {...params} />,
  },
  {
    path: "/not-found",
    renderer: (params = {}) => <NotFoundView {...params} />,
  },
];

export default routes;
