/* eslint-disable react/no-unescaped-entities */
import React from "react";
import {
  // alpha,
  useTheme,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Header = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box height={1} width={1} maxWidth={500}>
            <Box
              component={"img"}
              src={
                "https://res.cloudinary.com/fmrtech/image/upload/v1653358388/FmrTech/Illustrations/Petrol-pump_kmyhsh.svg"
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems={"center"} xs={12} md={6}>
        <Box data-aos={isMd ? "fade-right" : "fade-up"}>
          <Box marginBottom={2}>
            <Typography
              variant="h2"
              color="text.primary"
              sx={{
                fontWeight: 700,
              }}
            >
              Fuel your business with any{" "}
              <Typography
                component={"span"}
                variant={"inherit"}
                color={"primary"}
              >
                software needs.
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              We will make your product look modern and professional while
              saving you precious time.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Header;
