import React from "react";
import {
  Switch,
  Route,
  // Redirect
} from "react-router-dom";
import componentsRoutes from "./components/routes";

const Routes = () => {
  return (
    <Switch>
      {componentsRoutes.map((item, i) => (
        <Route
          key={i.toString()}
          exact
          path={item.path}
          render={() => item.renderer()}
        />
      ))}
      {/* <Redirect to={'/not-found'} /> */}
    </Switch>
  );
};

export default Routes;
